
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { isString } from 'lodash';
import { ActionTypes as OffersActionTypes } from '@/store/offers/types';
import Offer from '@/models/Offer';
import PwrVue from '@/components/PwrVue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import OpOffersSkeletonLoader from '@/components/Op/Offers/OpOffersSkeletonLoader.vue';
import OpOfferPreview from '@/components/Op/Offers/OpOfferPreview.vue';

const offersNamespace = 'offers';

@Component({
  components: { OpOfferPreview, OpOffersSkeletonLoader, PwrCardTitle, PwrCard, PwrBtn }
})
export default class OfferView extends PwrVue {
  @Action(OffersActionTypes.FETCH_OFFER, { namespace: offersNamespace })
  fetchOffer!: (offerUuid: string) => Promise<Offer | undefined>;

  private offer: Offer = {};

  private async mounted(): Promise<void> {
    this.resetAfkTimer();

    const offerUuid = this.$route.params.uuid;
    const tmpOffer = await this.offersHelper.recoverOffer(offerUuid);

    if (!tmpOffer) {
      await this.goBack();
    }

    if ( this.getConstantByPath(`offer.type.${tmpOffer?.type}`) !== 'TO_ADMINISTRATION') {
      tmpOffer?.files?.push( {
        uuid: '',
        source: 'default',
        name: 'rodo.pdf',
        filename: 'rodo.pdf',
        downloadLink: '/storage/rodo.pdf',
        size: 0,
        customProps: []
      });
    }


    // @ts-ignore
    this.offer = tmpOffer;
  }

  private getOfferPropByKey(key: string): string | number | undefined {
    if (this.offer) {
      // @ts-ignore
      return this.offer[key as keyof Offer];
    }

    return undefined;
  }

  get offerTextProps(): string[] {
    if (this.offer) {
      const allKeys = Object.keys(this.offer);

      // What fields to show and wit what order
      const scope = ['description', 'tasks', 'weOffer', 'requiredDocuments', 'additionalInfo'];

      scope.filter(
        (element: string) => allKeys.includes(element) && isString(this.getOfferPropByKey(element))
      );

      return scope;
    }

    return [];
  }

  private onApplyBtnClick(): void {
    if (this.offer && this.offer.eRecruiter) {
      window.location.href = this.offer.eRecruiter as string;
    }
  }
  private onGoBackButton() {
    this.goBack();
  }
}
